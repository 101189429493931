/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'gatsby';
import { Typography } from 'components/DesignSystemV2/Typography';
import { fromScreen } from 'utils/media-query/responsive.util';
import ButtonText from 'components/ButtonText';
import { colorsV2 } from '../../../style/colors-v2';

const Container = styled.div`
  display: grid;
  row-gap: 8px;
  align-content: flex-start;
`;

const IconWrapper = styled.div`
  display: none;

  ${fromScreen(458)} {
    display: block;
    background-color: ${colorsV2.black5};
    width: 52px;
    height: 52px;
    border-radius: 50%;
    padding: 12px;

    svg {
      color: ${colorsV2.neutralPrimary};
      width: 28px;
      height: 28px;
    }
  }
`;

export const CardAboutUs = props => {
  const { icon, title, link, descriptions } = props;
  return (
    <Container>
      <IconWrapper>{icon}</IconWrapper>
      <Typography
        variant="semi-bold/16-28"
        tabletVariant="semi-bold/20-28"
        v3
        as="h3"
      >
        {title}
      </Typography>
      {descriptions.map((eachDes, idx) => (
        <Typography
          key={idx}
          variant="regular/14-20"
          v3
          color={colorsV2.black80}
        >
          {eachDes}
        </Typography>
      ))}
      <a href={link} target="_blank" rel="noreferrer">
        <ButtonText content="Xem chi tiết" type="primary" />
      </a>
    </Container>
  );
};
