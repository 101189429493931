/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'gatsby';
import { Typography } from 'components/DesignSystemV2/Typography';
import { fromScreen } from 'utils/media-query/responsive.util';
import ButtonText from 'components/ButtonText';
import { colorsV2 } from '../../../style/colors-v2';

const Container = styled.div`
  display: grid;
  column-gap: 16px;
  grid-template-columns: 3px minmax(0, 1fr);
`;

const Content = styled.div``;

const Divider = styled.div`
  height: 100%;
  width: 3px;
  background-color: ${colorsV2.red100};
`;

export const CardAchievement = props => {
  const { value, label } = props;
  return (
    <Container>
      <Divider />
      <Content>
        <Typography
          variant="semi-bold/24-32"
          tabletVariant="semi-bold/28-36"
          color="black100"
          v3
        >
          {value}
        </Typography>
        <Typography
          variant="medium/14-20"
          tabletVariant="medium/16-20"
          color="black80"
          v3
        >
          {label}
        </Typography>
      </Content>
    </Container>
  );
};
